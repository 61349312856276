import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/site/src/components/episodeLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Sammanfattning`}</h2>
    <p>{`Vi drar en lättnadens suck över Home Assistant. Nextcloud 19 för COVID-19-tider. SpaceX och Lenovo satsar på Linux. Bekämpa diskriminering med en bundle från itch.io.`}</p>
    <h2>{`Nyheter`}</h2>
    <ul>
      <li parentName="ul">{`Nextcloud 19: `}<a parentName="li" {...{
          "href": "https://nextcloud.com/blog/nextcloud-hub-brings-productivity-to-home-office/"
        }}>{`https://nextcloud.com/blog/nextcloud-hub-brings-productivity-to-home-office/`}</a></li>
      <li parentName="ul">{`Lenovo Linux-certifierar sina arbetsdatorer: `}<a parentName="li" {...{
          "href": "https://news.lenovo.com/pressroom/press-releases/lenovo-brings-linux-certification-to-thinkpad-and-thinkstation-workstation-portfolio-easing-deployment-for-developers-data-scientists/"
        }}>{`https://news.lenovo.com/pressroom/press-releases/lenovo-brings-linux-certification-to-thinkpad-and-thinkstation-workstation-portfolio-easing-deployment-for-developers-data-scientists/`}</a></li>
      <li parentName="ul">{`Podcast Addict tillbaka: `}<a parentName="li" {...{
          "href": "https://www.reddit.com/r/PodcastAddict/comments/gmol2g/podcast_addict_is_back_on_the_play_store/"
        }}>{`https://www.reddit.com/r/PodcastAddict/comments/gmol2g/podcast_addict_is_back_on_the_play_store/`}</a></li>
      <li parentName="ul">{`SpaceX, Linux i rymden: `}<a parentName="li" {...{
          "href": "https://www.zdnet.com/article/spacex-weve-launched-32000-linux-computers-into-space-for-starlink-internet/"
        }}>{`https://www.zdnet.com/article/spacex-weve-launched-32000-linux-computers-into-space-for-starlink-internet/`}</a></li>
    </ul>
    <h2>{`Otrevligheter`}</h2>
    <ul>
      <li parentName="ul">{`Linux sucks 2020: `}<a parentName="li" {...{
          "href": "https://lbry.tv/@Lunduke:e/LinuxSucks2020:b"
        }}>{`https://lbry.tv/@Lunduke:e/LinuxSucks2020:b`}</a>{` `}</li>
    </ul>
    <h2>{`Trevligheter`}</h2>
    <ul>
      <li parentName="ul">{`Home Assistant lyssnade: `}<a parentName="li" {...{
          "href": "https://www.home-assistant.io/blog/2020/05/26/installation-methods-and-community-guides-wiki/"
        }}>{`https://www.home-assistant.io/blog/2020/05/26/installation-methods-and-community-guides-wiki/`}</a></li>
      <li parentName="ul">{`Bundle för 'Racial Justice and Equality': `}<a parentName="li" {...{
          "href": "https://itch.io/b/520/bundle-for-racial-justice-and-equality"
        }}>{`https://itch.io/b/520/bundle-for-racial-justice-and-equality`}</a></li>
    </ul>
    <h2>{`Utmaningar`}</h2>
    <h3>{`Alex PinePhone`}</h3>
    <ul>
      <li parentName="ul">{`Inget nytt, ska fixa multi-boot så småningom`}</li>
    </ul>
    <h3>{`Sebs Linuxäventyr`}</h3>
    <ul>
      <li parentName="ul">{`Joplin dog ut`}</li>
    </ul>
    <h3>{`Vim`}</h3>
    <ul>
      <li parentName="ul">{`Proper Vim för Alex`}</li>
      <li parentName="ul">{`Co-vim`}</li>
    </ul>
    <h2>{`Meta`}</h2>
    <ul>
      <li parentName="ul">{`Rättning: ROCKPro64, inte PineRock64 för SBC med m.2-disk`}</li>
    </ul>
    <h2>{`Kontakta oss`}</h2>
    <ul>
      <li parentName="ul">{`Hemsida: `}<a parentName="li" {...{
          "href": "https://trevligmjukvara.se"
        }}>{`https://trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Mail: `}<a parentName="li" {...{
          "href": "mailto:kontakt@trevligmjukvara.se"
        }}>{`kontakt@trevligmjukvara.se`}</a></li>
      <li parentName="ul">{`Twitter: `}<a parentName="li" {...{
          "href": "https://twitter.com/trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`Telegram: `}<a parentName="li" {...{
          "href": "https://t.me/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
      <li parentName="ul">{`Mastodon: `}<a parentName="li" {...{
          "href": "https://fikaverse.club/@trevligmjukvara"
        }}>{`@trevligmjukvara`}</a></li>
      <li parentName="ul">{`GitHub: `}<a parentName="li" {...{
          "href": "https://github.com/trevligmjukvara"
        }}>{`Trevlig Mjukvara`}</a></li>
    </ul>
    <h2>{`Tack till`}</h2>
    <ul>
      <li parentName="ul">{`Ljudeffekter från `}<a parentName="li" {...{
          "href": "https://www.zapsplat.com/"
        }}>{`https://www.zapsplat.com/`}</a></li>
      <li parentName="ul">{`Musik från `}<a parentName="li" {...{
          "href": "https://filmmusic.io"
        }}>{`https://filmmusic.io`}</a><ul parentName="li">
          <li parentName="ul">{`"Pixelland" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"NewsSting" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Toccata and Fugue in D Minor" av Kevin MacLeod`}</li>
          <li parentName="ul">{`"Beautiful World - Epic and Uplifting Motivational Trailer" av Rafael Krux`}</li>
          <li parentName="ul">{`"Black Knight" av Rafael Krux`}</li>
          <li parentName="ul">{`"Pixel Peeker Polka - Faster" av Kevin MacLeod`}</li>
          <li parentName="ul">{`Licens för alla: CC BY 4.0 (`}<a parentName="li" {...{
              "href": "http://creativecommons.org/licenses/by/4.0/"
            }}>{`http://creativecommons.org/licenses/by/4.0/`}</a>{`)`}</li>
        </ul></li>
      <li parentName="ul">{`Grafik och font i loggan:`}<ul parentName="li">
          <li parentName="ul">{`Ok-emoji: emojione version 2.2.7, `}<a parentName="li" {...{
              "href": "https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg"
            }}>{`https://commons.wikimedia.org/wiki/File:Emojione_1F44C.svg`}</a>{` (CC BY 4.0)`}</li>
          <li parentName="ul">{`Font: Sulphur Point av Dale Sattler, `}<a parentName="li" {...{
              "href": "https://fonts.google.com/specimen/Sulphur+Point"
            }}>{`https://fonts.google.com/specimen/Sulphur+Point`}</a>{` (OFL)`}</li>
        </ul></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      